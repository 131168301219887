import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import UserContext from "../context/userContext";

const Login = () => {
    const { setUser } = useContext(UserContext);

    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
    });
    const { email, password } = inputValue;

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            [name]: value,
        });
    };

    const handleError = (err) =>
        toast.error(err, {
            position: "bottom-left",
        });
    const handleSuccess = (msg) =>
        toast.success(msg, {
            position: "bottom-left",
        });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify(inputValue),
            });
            const data = await res.json();

            const { success, message } = data;
            console.log("data.username is ", data.username)
            if (success) {
                handleSuccess(message);

                setUser(data.username)
                // localStorage.setItem("user", inputValue.email)

                setTimeout(() => {
                    navigate("/account");
                }, 1000);
            } else {
                handleError(message);
            }
        } catch (error) {
            console.log(error);
        }
        setInputValue({
            ...inputValue,
            email: "",
            password: "",
        });
    };
    return (
        <div className="Login justify-content-center d-flex h-100 align-items-center">
            <div className="row col-xl-6 col-md-8 col-11 py-5 mx-2">
                <form onSubmit={handleSubmit} className="p-4 p-md-5 border rounded-3 bg-light">
                    <h1 className="display-2 mb-4">Log In</h1>
                    <div className="form-floating mb-3">
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            value={email}
                            onChange={handleOnChange}
                        />
                        <label className="float-bg" htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="floatingPassword"
                            value={password}
                            onChange={handleOnChange}
                        />
                        <label className="float-bg" htmlFor="floatingPassword">Password</label>
                    </div>
                    <button className="w-100 btn btn-lg btn-primary" type="submit">
                        Log In
                    </button>
                    <hr className="mt-4 mb-3" />
                    <small className="text-muted">
                        <Link className="mb-3" to="/forgot-password">Forgot password?</Link>
                    </small>
                    <br />
                    <small className="text-muted">
                        Don't have an account yet? <Link to="/signup">Sign up</Link>
                    </small>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
};

export default Login;
