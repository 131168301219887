import { useEffect, useState } from "react";

const AdminPanel = (props) => {

    let user = props.user

    return (
        <div className="AdminPanelHome h-100 d-flex flex-column align-items-center">
            Admin Home
        </div>
    );
};

export default AdminPanel;
