import HbAccount from "./HbAccount";
import HbCart from "./HbCart";
import HbProducts from "./HbProducts";
import { useState, useEffect, useRef } from "react";
import "../Nav.css";

const HamburgerMenu = (props) => {
    const [categories, setCategories] = useState([]);
    const [classList, setClassList] = useState(
        "HamburgerMenu navbar-nav mb-0 px-4 pt-3 align-items-start menu-closed"
    );

    const menuShowing = props.menuShowing;
    const setMenuShowing = props.setMenuShowing;

    const menuRef = useRef();

    const handleOutsideClick = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setMenuShowing(false);
        }
    }

    const closeMenu = () => {
        setMenuShowing(false);
    }

    const getCategories = async () => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/categories`);
        let cData = await res.json();

        setCategories(cData);
    };

    useEffect(() => {
        getCategories();
        setClassList(
            `HamburgerMenu navbar-nav mb-0 pt-3 align-items-start ${menuShowing ? "menu-open" : "menu-closed"}`
        );

        document.addEventListener('mousedown', handleOutsideClick);

        // Remove event listener when the component unmounts
        return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
        };

    }, [menuShowing]);

    return (
        <ul className={classList} ref={menuRef}>
            <button
                onClick={() => setMenuShowing(false)}
                type="button"
                className="btn-close btn-close-white align-self-end"
            ></button>
            <HbProducts closeMenu={closeMenu} />
            <HbAccount closeMenu={closeMenu} />
            <HbCart closeMenu={closeMenu} />
        </ul>
    );
};

export default HamburgerMenu;
