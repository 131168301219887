import { useNavigate, useLocation } from "react-router-dom";

const VerifyEmail = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const path = location.pathname;


    const handleClick = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (response.ok) {
                console.log('Post request successful');
                navigate('/');
            } else {
                console.log('Post request failed');
            }
        } catch (error) {
            console.log('Network error:', error);
        }
    }

    return (
        <div className="VerifyEmail justify-content-center d-flex flex-column h-100 align-items-center">
            <h1>Verify Email</h1>
            <button onClick={handleClick} className="btn btn-primary btn-lg">Verify Email</button>
        </div>
    );
};

export default VerifyEmail;
