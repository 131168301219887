import { useEffect, useState } from "react";
import NewOrderBar from "../../NewOrderBar";

function UserOrdersPanel(props) {
    let [orders, setOrders] = useState([]);

    const user = props.user;
    console.log("user:", user);

    useEffect(() => {
        const getOrders = async () => {
            if (user) {
                const prodRes = await fetch(`${process.env.REACT_APP_API_URL}/products`);
                const products = await prodRes.json();

                let arr = user.orders;

                for (let i = 0; i < arr.length; i++) {
                    arr[i]["p"] = products.find((p) => p.product_id === arr[i].product_id);
                }

                console.log("arr:", arr);
                setOrders(arr);
            } else {
                console.log("no user ");
            }
        };
        getOrders();
    }, [user]);

    if (orders.length === 0 || !orders) {
        return <></>;
    }

    return (
        <div className="UserOrdersPanel d-flex flex-column align-items-center">
            <div className="col-12 text-start ms-3">
                <h3 className="display-4 col-12">Your orders:</h3>
                {orders.map((order) => (
                    <div key={order.p.product_id} className="col-12 d-flex flex-row align-items-center mx-3 border">
                        {/* <div className="col-8 flex-row align-items-center d-flex"> */}
                            <div className="col-2 d-flex justify-content-start">
                                <img src={order.p.image} alt="" className="img-fluid" />
                            </div>
                            <div className="col-2 d-flex justify-content-center fw-bold">{order.p.name}</div>
                            <div className="col-3 d-flex justify-content-center">You Ordered: {order.qty}</div>
                            {/* <div className="col-2">Min Qty to Order: {order.p.min_qty}</div> */}
                            <div className="col-3">
                                {/* <OrderBar min_qty={order.p.min_qty} qty={order.qty}/> */}
                                <NewOrderBar product={order.p} order={order} barStyle={{height: "30px"}}/>

                            </div>
                        {/* </div> */}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UserOrdersPanel;
