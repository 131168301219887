import { useEffect, useState } from "react";

import HomeTab from "./UP_HomeTab";
import OrdersTab from "./UP_OrdersTab";
import SettingsTab from "./UP_SettingsTab";
import ListsTab from "./UP_ListsTab";


const UserPanelTabs = (props) => {

    const tab = props.tab
    const user = props.user

    if (tab === "home"){
        return(<HomeTab user={user}/>)
    }
    else if (tab === "orders"){
        return(<OrdersTab user={user}/>)
    }
    else if (tab === "lists"){
        return(<ListsTab user={user}/>)
    }
    else if (tab === "settings"){
        return(<SettingsTab />)
    }

    else {
        return (
            <>
                No Tab Rendered
            </>
        );
    }

};

export default UserPanelTabs;
