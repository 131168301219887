import { useEffect, useState } from "react";

const UserPanelSettings = (props) => {

    let user = props.user

    return (
        <div className="UserPanelSettings h-100 d-flex flex-column align-items-center mt-5">
            <h1>Settings</h1>
            <h2 className="lead fs-4 mt-5">(Under Construction)</h2>
        </div>
    );
};

export default UserPanelSettings;
