import { useEffect, useState } from "react";
import CartUpdater from "../context/cartUpdater";
import ListUpdater from "./ListUpdater";
import { formatPrice } from "../util/functions";
import NewOrderBar from "./NewOrderBar";

const Product = () => {
    const [product, setProduct] = useState({});
    const [order, setOrder] = useState({});
    const [qty, setQty] = useState(0);



    const formattedPrice = (whole, fraction) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return formatter.format((qty * product.price.whole) + (qty * (product.price.fraction/100)))
    }


    const handleOnChange = (e) => {
        e.preventDefault()
        setQty(e.target.value)
    }

    useEffect(() => {
        const product_id = window.location.pathname.replace("/products/", "");
        const getProduct = async () => {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/products/${product_id}`);
            let productData = await res.json();
            console.log(productData);
            setProduct(productData);
        };
        const getOrder = async () => {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/orders/product/${product_id}`);
            let orderData = await res.json();
            setOrder(orderData);
        };

        getOrder();
        getProduct();
    }, []);

    if (product.name === undefined || !product.name) {
        return <></>;
    }

    return (
        <div className="Product container-fluid mt-5">
            <div className="d-flex justify-content-center">
                <div className="col h-75">
                    <img src={product.image} className="img-fluid" id="product-view-img" alt="Bootstrap Themes" />
                </div>

                <div className="col">
                    <h3 className="">{product.name}</h3>
                    <p className="">{product.description}</p>

                    <p className="">Min Qty for Order: {product.min_qty}</p>
                    <div className="col-8 mb-4 mx-auto">
                        <NewOrderBar product={product} order={order} barStyle={{height: "30px"}}/>
                    </div>

                    <p className="">
                        Price per unit: ${product.price.whole}.{formatPrice(product.price.fraction)}
                    </p>
                    <div className="col-4 mx-auto d-flex justify-content-center align-items-center">
                        <p className="py-2 mb-0 me-3">Qty&nbsp;</p>
                        <input id="quantity" onChange={handleOnChange} type="number" placeholder="0" style={{width: "4rem"}}/>

                    </div>
                    <p className="">Total Price</p>
                    <p className="lead" id="total">
                        {formattedPrice()}
                    </p>
                    <div className="row d-flex justify-content-center mt-5 col-6 mx-auto">
                        <div className="col-6 px-0">
                            <CartUpdater btnStyle="btn-lg" item={{ product: product, quantity: 1, image: product.image }} />
                        </div>
                        <div className="col-6 px-0">
                            <ListUpdater item={{ product: product, quantity: 1, image: product.image }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
