import { Link } from "react-router-dom";
const AdminCardButtons = (props) => {
    let p = props.product;
    let productLink = "/products/" + p.product_id;

    const deleteProduct = async (product) => {
        const options = {
            method: "DELETE",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ product_ids: [p.product_id] }),
        };
        let res = await fetch(`${process.env.REACT_APP_API_URL}/products`, options);
        // console.log(res.status);
        let data = await res.json();
        console.log(data);
        props.setUpdated(true);
    };

    return (
        <div className="AdminButtons m-0 mb-3 py-1 d-flex" style={{ height: "20%" }}>
            <Link to={productLink} className="btn btn-primary admin-btn mx-1 d-flex align-items-center">
                View
            </Link>

            <button
                className="btn btn-primary admin-btn mx-1"
                data-bs-toggle="modal"
                data-bs-target="#editProductModal"
                type="button"
                onClick={() => props.setCurrentProduct(p)}
            >
                Edit
            </button>

            <button className="btn btn-danger admin-btn mx-1" onClick={() => deleteProduct(p)}>
                Delete
            </button>
        </div>
    );
};

export default AdminCardButtons;
