import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import UserContext from "../../../context/userContext";
import UserPanelTabs from "./UP_Tabs";

const UserPanelMain = (props) => {
    const [tab, setTab] = useState("home");
    const navigate = useNavigate();
    const [cookie, removeCookie] = useCookies(["token"]);

    const { user, setUser } = useContext(UserContext);

    const userInfo = props.user;

    const handleClick = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/resend-email`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                "user-email": userInfo.email,
                ev_str: userInfo.ev_str,
            }),
        });
        // Add toast message here
        // toast.success("Verification email has been resent", {
        //     position: "bottom-left",
        // });
    };

    useEffect(() => {}, [tab, user]);

    const logout = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });
        console.log("ran logout from UP_Main");
        removeCookie("token");
        setUser(null);
        navigate("/login");
    };

    if (!userInfo.email_verified) {
        return (
            <div className="UserPanel justify-content-center">
                <div className="col-12 d-flex flex-row justify-content-end">
                    <div className="col-3 d-flex flex-column align-items-end px-1 pt-3 pe-3">
                        <h1 className="display-5">Hello, {userInfo.username}</h1>
                    </div>
                </div>
                <div className="row">
                    <h2 className="display-3">Please verify your email to continue.</h2>
                    <div className="col-4 mx-auto">
                        <button className="btn btn-lg btn-primary mx-auto" onClick={handleClick}>
                            Resend Verification Email
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="UserPanel justify-content-center">
            <div className="col-12 d-flex flex-row justify-content-end">
                <div className="col-3 d-flex flex-column align-items-end px-1 pt-3 pe-3">
                    <h1 className="display-5">Hello, {userInfo.username}</h1>
                </div>
            </div>
            <div className="col-12 mb-5">
                <div className="container d-flex justify-content-center">
                    <div className="col-lg-8 col-10">
                        <div className="row py-1">
                            <div className="col-sm-3 col-6 d-flex flex-row justify-content-center">
                                <button onClick={() => setTab("orders")} className="acc-panel-btn btn btn-outline-primary  fs-4 w-100 py-3 my-1">
                                    Orders
                                </button>
                            </div>
                            <div className="col-sm-3 col-6 d-flex flex-row justify-content-center">
                                <button onClick={() => setTab("lists")} className="acc-panel-btn btn btn-outline-primary  fs-4 w-100 py-3 my-1">
                                    Lists
                                </button>
                            </div>
                            <div className="col-sm-3 col-6 d-flex flex-row justify-content-center">
                                <button onClick={() => setTab("settings")} className="acc-panel-btn btn btn-outline-primary  fs-4 w-100 py-3 my-1">
                                    Settings
                                </button>
                            </div>
                            <div className="col-sm-3 col-6 d-flex flex-row justify-content-center">
                                <button onClick={logout} className="acc-panel-btn btn btn-outline-danger  fs-4 w-75 py-3 my-1">
                                    Log out
                                </button>
                            </div>
                        </div>
                        <div className="row py-1"></div>
                    </div>
                </div>
            </div>
            <UserPanelTabs tab={tab} user={userInfo} />
            {/* <h3 className="display-4">Your orders:</h3>
                {orders.map((order) => (
            <div key={order.product_id} className="col-3 m-1 p-2 ms-5 border border-dark rounded">
                <h2>Id: {order.product.id}</h2>
                <h2>Name: {order.product.name}</h2>
                <h2>
                    Price: ${order.product.price.whole}.{order.product.price.fraction}
                </h2>

                <button className="btn btn-success">Track Order</button>
                <button className="btn btn-danger ms-5">Cancel Order</button>
            </div>
        ))} */}
        </div>
    );
};

export default UserPanelMain;
