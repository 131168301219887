const filterProducts = (productsData, search) => {

    const searchFilter = (p) => {
        console.log("single word query")
        const query = search.trim()
        if(p.category.toLowerCase().includes(query.toLowerCase())){
            return true
        }
        else if(p.name.toLowerCase().includes(query.toLowerCase())){
            return true
        }
        else if(p.description.toLowerCase().includes(query.toLowerCase())){
            return true
        }
        // Logic for multi-word searches (contains first AND second word)
        return false
    }

    const multiWordQuery = (terms) => {
        console.log("multi word query")
        console.log("terms:", terms)
        let arr = []
        // loop through all products only ONCE
        for (let p of productsData){
            let i = 0
            console.log("in loop", p.product_id)
            // Loop through terms to match to product, if at any point a term is not found, loop excludes current product and checks next one
            // Check for cases where product contains one of the query terms
            while (i <= terms.length) {
                console.log("i:", i)
                // if i === terms.length, it means all terms are included within the product, and it should be pushed into returned array
                if (i === terms.length){
                    arr.push(p)
                    break
                }
                // If a product contains a search term, 'continue' the loop to skip the 'break' (to continue checking this product for remaining search terms)
                if (p.category.toLowerCase().includes(terms[i].toLowerCase())){
                    i++
                    continue
                }
                else if (p.name.toLowerCase().includes(terms[i].toLowerCase())){
                    i++
                    continue
                }
                else if (p.description.toLowerCase().includes(terms[i].toLowerCase())){
                    i++
                    continue
                }
                // if the code reaches here, it means a search term was not found anywhere in a product, so it should be excluded.
                else {
                    break
                }
            }
        }

        arr = arr.length === 0 ? ["none"] : arr

        return arr
    }

    console.log("search", search)
    if (search) {

        /*
            If there's more than one word in the query,
            return only results that contain ALL words

            Otherwise, just filter based on the one word
        */

        if (search.trim().split(' ').length > 1) {
            return multiWordQuery(search.trim().split(' '))
        }

        let filtered = productsData.filter(searchFilter);

        return filtered.length === 0 ? ["none"] : filtered
    }
    console.log("unfiltered results")
    return productsData;
};

export { filterProducts }
