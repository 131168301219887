import { useState } from "react";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import EditProductForm from "./EditProductForm";

function EditProductModal(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    console.log("current in modal", props.currentProduct)

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <div
                className="modal fade"
                id="editProductModal"
                tabIndex="-1"
                aria-labelledby="editProductModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-fullscreen-xl-down modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-3" id="editProductModalLabel">
                                Edit Product
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <EditProductForm categories={props.categories} setUpdated={props.setUpdated} currentProduct={props.currentProduct} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography onMouseLeave={handleClose} sx={{ p: 2 }}>
                    Successfully added new product!
                </Typography>
            </Popover>
        </>
    );
}

export default EditProductModal;
