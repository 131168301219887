let getTitle = (str) => {
    let idx1 = str.indexOf(`<h1 class="name`)
    let idx2 = str.slice(idx1).indexOf(">") + idx1
    let idx3 = str.slice(idx2).indexOf("<") + idx2

    let title = str.slice(idx2 + 1, idx3)

    return title
}

let getPrice = (str) => {
    let idx1 = str.indexOf(`<div class="price"`)
    let idx2 = str.slice(idx1).indexOf(">") + idx1
    let idx3 = str.slice(idx2).indexOf("<") + idx2

    let price = str.slice(idx2 + 1, idx3)
    price = price.split('-').at(-1)
    let mult = price.includes('.') ? 100 : 1

    price = price.match(/[0-9]/g).join('') / mult
    console.log('price', price)
    return price
}

let getImage = (str) => {
    let s = str.slice(str.indexOf(`class="magnifier-box"`))
    let idx1 = s.indexOf(`src="`) + 5
    let idx2 = s.slice(idx1).indexOf(`"`) + idx1

    let imageURL = s.slice(idx1, idx2)
    console.log("image", idx1, idx2)

    return imageURL
}

let getWeight = (str) => {
    let idx1 = str.indexOf(`ant-descriptions-item-colon">Weight per Unit</th>`)
    let idx2 = str.slice(idx1).indexOf(`item-content">`) + idx1 + 13
    let idx3 = str.slice(idx2).indexOf("<") + idx2

    let weight = str.slice(idx2 + 1, idx3)

    return weight
}

let getDimensions = (str) => {
    let idx1 = str.indexOf(`ant-descriptions-item-colon">Dimensions per Unit</th>`)
    let idx2 = str.slice(idx1).indexOf(`item-content">`) + idx1 + 13
    let idx3 = str.slice(idx2).indexOf("<") + idx2

    let dimensions = str.slice(idx2 + 1, idx3)

    return dimensions
}


let getMinQty = (str) => {
    let idx1 = str.indexOf(`span class="label"`)
    let idx2 = str.slice(idx1).indexOf(">") + idx1
    let idx3 = str.slice(idx2).indexOf("<") + idx2

    let minQty = str.slice(idx2 + 1, idx3)
    minQty = minQty.match(/[0-9]/g).join('')

    return minQty
}

let getDescription = (str) => {
    let idx1 = str.indexOf(`Key Specifications/ Special Features:`)
    while (str.indexOf(`Key Specifications/ Special Features:`, idx1 + 1) > 0) {
        idx1 = str.indexOf(`Key Specifications/ Special Features:`, idx1 + 1)
    }
    let idx2 = str.indexOf("<p>", idx1 + 1)
    let idx3 = str.indexOf("</p>", idx2 + 1)

    let desc = str.slice(idx2 + 3, idx3)
    desc = desc.replaceAll("<br>", '\n')

    console.log("desc", desc)
    return desc
}

export {
    getTitle,
    getPrice,
    getImage,
    getWeight,
    getDimensions,
    getMinQty,
    getDescription
}
