import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";


const ForgotPassword = () => {
    const [email, setEmail] = useState('');


    const handleOnChange = (e) => {
        const val = e.target.value

        setEmail(val)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/forgot-password`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({ email }),
            });
            const data = await res.json();

            const { success, message } = data;
            if (success) {
                handleSuccess(message);
            } else {
                handleError(message);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleError = (err) =>
        toast.error(err, {
            position: "bottom-left",
        });
    const handleSuccess = (msg) =>
        toast.success(msg, {
            position: "bottom-left",
        });

    return (
        <div className="Login justify-content-center d-flex h-100 align-items-center">
            <div className="row col-xl-6 col-lg-8 col-10 py-5 mx-2">
                <form onSubmit={handleSubmit} className="p-4 p-md-5 border rounded-3 bg-light">
                    <h1 className="lead text-start fs-4 ms-1 mb-4">Please enter the email associated with your Marafyde account</h1>
                    <div className="form-floating my-3">
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            value={email}
                            onChange={handleOnChange}
                        />
                        <label className="float-bg" htmlFor="floatingInput">Email address</label>
                    </div>
                    <button className="btn btn-lg btn-primary" type="submit">
                        Reset Password
                    </button>
                </form>
                {/* <ToastContainer /> */}
            </div>
        </div>
    );
}

export default ForgotPassword
