import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { useNavigate } from "react-router-dom";


const Checkout = (props) => {
    let app_id = process.env.REACT_APP_SQ_SANDBOX_ID;
    let navigate = useNavigate();

    let { cart, user, setCart, removeFromCart } = props;

    const submitPayment = async (token, verifiedBuyer) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/pay`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                sourceId: token.token,
                locationId: "LJBZPNJ90BG3P"
            }),
        });
        console.log("res: ", await response);

        if (response.ok) {
            alert("Payment Successful");
            await submitOrder();
            navigate("/account");
        } else {
            alert("Payment Failed, please try again");
        }

    }

    const submitOrder = async () => {
        // e.preventDefault();

        let orders = [];

        for (let item of cart) {
            orders.push({
                product_id: item.product_id,
                qty: item.qty,
            });
        }

        const options = {
            method: "PUT",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: user,
                orders: orders,
            }),
        };

        let res = await fetch(`${process.env.REACT_APP_API_URL}/orders`, options);
        let data = await res.json();

        console.log("submitted", data);

        // empties cart in state
        setCart([]);
        // empties cart in db
        await removeFromCart(0);
    };

    return (
        <div className="Checkout justify-content-center d-flex my-auto align-items-center">
            <div className="row col-xl-4 col-md-6 col-9 mx-2">
                <PaymentForm
                    applicationId={app_id}
                    cardTokenizeResponseReceived={submitPayment}
                    locationId="LJBZPNJ90BG3P"
                >
                    <CreditCard
                        buttonProps={{
                            css: {
                                backgroundColor: "#2fb4ff",
                                color: "#fff",
                                "&:hover": {
                                    backgroundColor: "#20a4ff",
                                },
                            },
                        }}
                    />
                </PaymentForm>
            </div>
        </div>
    );
};

export default Checkout;
