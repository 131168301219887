const formatPrice = (num) => {
    let n = num.toString()
    return n + "00".slice(n.length)
}

const calcCartPrice = (item) => {
    let whole = item.product_price.whole
    let fraction = item.product_price.fraction
    let qty = item.qty

}

const shortenName = (str) => {
    if (str.length > 35){
        return str.slice(0, 30).trim() + "..."
    }
    return str
}

const capsFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export {
    formatPrice,
    calcCartPrice,
    shortenName,
    capsFirstLetter
}
