import { Link } from "react-router-dom";

const HbCart = (props) => {

    return (
        <li className="HbCart hb-link-item nav-item ms-1">
            <Link className="nav-link d-flex justify-content-start align-items-center py-0 pe-0 w-75" onClick={props.closeMenu} to="/cart">
                <div className="col-2 d-flex align-items-start">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        fill="currentColor"
                        className="bi bi-cart"
                        viewBox="0 0 16 16"
                        stroke=""
                    >
                        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                    </svg>
                </div>
                <div className="col-10 d-flex align-items-start">
                    <span className="hb-link-text">
                        &nbsp;Cart
                    </span>
                </div>
            </Link>
        </li>

    );
};

export default HbCart;
