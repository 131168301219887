import { useContext, useState, useEffect } from "react";
import userContext from "../context/userContext";


import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

let ListUpdater = (props) => {
    const { user, setUser } = useContext(userContext);
    const [ lists, setLists ] = useState([]);
    const [ loggedIn, setLoggedIn ] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const product = props.item.product

    const isLoggedIn = () => {
        try{
            if ( !loggedIn && user.length > 0){
                getUserLists()
                setLoggedIn(true)
                return true
            }
            return false
        }
        catch {
            return false
        }
    }

    const addToList = async (e) => {
        const body = {
            list: e.target.innerText,
            item: product
        }
        console.log(body)
        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/${user}/lists`, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(body),
        });

    }

    const getUserLists = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/${user}/lists`)
        const listsRes = await res.json();
        console.log(listsRes)

        setLists(listsRes)
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        addToList(e);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        isLoggedIn()
    }, [])


    return (
        <>
            <button
                className="dropdown dropdown-toggle text-white btn btn-primary btn-lg"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                aria-describedby={id}
            >
                Add to List
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography onMouseLeave={handleClose} sx={{ p: 2 }}>Added item to list!</Typography>
            </Popover>

            <ul className="dropdown-menu">
                {/* loop through user's lists */}
                { !loggedIn
                    ? <li><button className="dropdown-item" href="#">Not Logged In</button></li>
                    : lists.map((list) => (
                        <li key={list.name}><button onClick={handleClick} className="dropdown-item" href="#">{list.name}</button></li>
                    ))
                }
            </ul>
        </>
    );
};

export default ListUpdater;
