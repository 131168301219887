import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import UserPanel from "./AccountPanels/User/UP_Main";
import AdminPanel from "./AccountPanels/Admin/AP_Main";
import userContext from "../context/userContext";
import CartContext from "../context/cartContext";

const AccountPage = () => {
    const { user, setUser } = useContext(userContext);
    const { cart, setCart } = useContext(CartContext);
    const [cookies, removeCookie] = useCookies(['token']);
    const [userData, setUserData] = useState({username: ""})

    let navigate = useNavigate()

    const verifyCookie = async () => {
        // console.log("ran verifyCookie in acc page");
        if (!cookies.token || cookies.token === 'undefined') {
            // navigate("/login");
            // cookies.updateCookies();
            // console.log("not logged in, no token in cookies.");
        }
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();

        const { status, authUser, authEmail, authCart } = data

        // console.log("status: ", status)
        // console.log("authUser: ", authUser)
        if (status === "true" || status === true) {
            setUserData({ username: authUser, email: authEmail })
            setCart(authCart)
            getUserInfo(authUser);
        }

        // setUser(authUser)

        // Check status from post request to auth - if returns false, remove cookie and navigate back to login page
        // if (!status) {
        //     // console.log("status returned false | cookies.token:", cookies.token)
        //     setUsername("");
        //     removeCookie("token")
        //     navigate("/login");
        // }
        return status;
    };

    const getUserInfo = async (username) => {
        // let res = await fetch(`${process.env.REACT_APP_API_URL}/users/${userData.email}`)
        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/${username}`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });
        let data = await res.json()
        if (data.account_type){
            setUserData(data)
            // console.log("getUserInfo", data)
        }
        else {
            console.log("User not found")
        }
    }

    const getCart = async () => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/cart/${user}`)
        let data = await res.json()
        setCart(data);
    }

    useEffect(() => {
        verifyCookie();
    }, [user]);

    // should be using auth
    // if (!cookies.token || cookies.token === "undefined") {
    if (!userData.username || userData.username === null) {
        return (
            <div className="AccountPage">
                {/* <h1 className="display-2">Not Logged In.</h1> */}
            </div>
        );
    }

    else if (userData.account_type === "user"){
        return (
            <UserPanel user={userData}/>
        );
    }

    else if (userData.account_type === "admin"){
        return (
            <AdminPanel user={userData}/>
        )
    }
    else {
        // navigate("/login")
        <div className="AccountPage">

        </div>
    }
}

export default AccountPage;
