import { useState, useEffect } from "react";
import AdminProductCard from "./Products/AdminProductCard";
import NewProductModal from "./Products/NewProductModal";
import EditProductModal from "./Products/EditProductModal";

function ProductsPanel() {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentProduct, setCurrentProduct] = useState({
        name: "name",
        price: { whole: 10, fraction: 99 },
        comp_price: { whole: 19, fraction: 99 },
        category: "category",
        description: "description",
        image: "img",
        min_qty: 1,
        weight: "1.0 grams",
        dimensions: "1.0 x 1.0 x 1.0 cm",
        merchant_info: {
            name: "merchant name",
            email: "merchant email",
            website: "merchant website",
            phone: "merchant phone",
        },
    });
    const [updated, setUpdated] = useState(false);

    const getProducts = async () => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/products`);
        let productData = await res.json();
        // console.log(productData)
        console.log("got products");
        setProducts(productData);
    };

    const getCategories = async () => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/categories`);
        let cData = await res.json();
        console.log("got categories");
        setCategories(cData);
    };

    // const uploadProducts = async () => {
    //     const response = await fetch(`${process.env.REACT_APP_API_URL}/products`, {
    //         method: "POST", // *GET, POST, PUT, DELETE, etc.
    //         mode: "cors", // no-cors, *cors, same-origin
    //         credentials: "same-origin", // include, *same-origin, omit
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify(products), // body data type must match "Content-Type" header
    //     });
    //     return response.json(); // parses JSON response into native JavaScript objects
    // };

    useEffect(() => {
        getProducts();
        getCategories();
        setUpdated(false);
    }, [updated]);

    if (!products || products.length === 0) {
        return (
            <div className="AP_ProductList justify-content-center col-11 row">
                <div className="col-12">
                    <h1 className="display-4">Products:</h1>
                </div>
            </div>
        );
    }

    return (
        <div className="AP_ProductList justify-content-center col-11 row">
            <div className="col-12">
                <h1 className="display-4">Products:</h1>
            </div>
            <div className="col-12 mx-auto d-flex flex-row justify-content-end mb-3">
                <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#newProductModal"
                    type="button"
                >
                    Create new product
                </button>
            </div>
            {products.map((product) => (
                <AdminProductCard
                    key={product.product_id}
                    productData={product}
                    setUpdated={setUpdated}
                    currentProduct={currentProduct}
                    setCurrentProduct={setCurrentProduct}
                />
            ))}
            <NewProductModal products={products} setProducts={setProducts} categories={categories} />
            {currentProduct.name ? (
                <EditProductModal
                    categories={categories}
                    setUpdated={setUpdated}
                    currentProduct={currentProduct}
                    setCurrentProduct={setCurrentProduct}
                />
            ) : (
                <></>
            )}
        </div>
    );
}

export default ProductsPanel;
