import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import UserContext from "../context/userContext";

const Auth = () => {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies([]);
    const [username, setUsername] = useState("");

    const { user, setUser } = useContext(UserContext);


    const handleSubmit = () => {
        return 0
    }

    useEffect(() => {
        const verifyCookie = async () => {
            if (!cookies.token) {
                navigate("/login");
            }
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth`, {}, { withCredentials: true });
            const { status, user } = data;
            setUsername(user);
            // Check status from post request to auth - if returns false, remove cookie and navigate back to login page
            return status
                ? toast(`Hello ${user}`, {
                      position: "top-right",
                  })
                : (removeCookie("token"), navigate("/login"));
        };
        verifyCookie();
    }, [cookies, navigate, removeCookie]);

    const Logout = () => {
        removeCookie("token");
        localStorage.setItem("user", null)
        setUser(null)
        navigate("/login");
    };

    return (
        <>
            <div className="Account justify-content-center d-flex h-100 align-items-center">
                <div className="row col-xl-6 col-md-8 col-11 py-5 mx-2 justify-content-center">
                    <div className="col-6">
                    <form onSubmit={handleSubmit} className="p-4 p-md-5 border rounded-3 bg-light">
                    <h1 className="display-5 mb-4">Add a new product</h1>
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            name="item_name"
                            className="form-control"
                        />
                        <label htmlFor="floatingInput">Item Name</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            name="item_name"
                            className="form-control"
                        />
                        <label htmlFor="floatingInput">Item Description</label>
                    </div>

                    <button className="w-100 btn btn-lg btn-primary" type="submit">
                        Create Item
                    </button>
                    <hr className="my-4" />
                </form>
                    </div>
                    <h4>
                        Welcome <span>{username}</span>
                    </h4>
                    <div className="col-3">
                        <button onClick={Logout}>LOGOUT</button>

                    </div>
                    <ToastContainer />
                </div>
            </div>
        </>
    );
};

export default Auth;
