import { Link } from "react-router-dom";

const NavLeft = () => {
    return (
        <>
            <Link className="navbar-brand pb-0" to="/">
                <img className="img-fluid" style={{height: "4rem"}} src="https://i.imgur.com/5xKS0qR.png" alt="Marafyde Navbar Logo"/>
            </Link>
        </>
    );
};

export default NavLeft
