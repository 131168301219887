import { useState } from "react";

import {
    getTitle,
    getPrice,
    getImage,
    getWeight,
    getDimensions,
    getMinQty,
    getDescription,
} from "../../../../util/htmlParsing";

const NewProductForm = (props) => {
    const setProducts = props.setProducts;
    const products = props.products;
    const categories = props.categories;

    const [formView, setFormView] = useState("default");
    const [importData, setImportData] = useState("");
    const [inputValue, setInputValue] = useState({
        productName: "",
        productPrice: "",
        productCompPrice: "",
        productCategory: "",
        productDescription: "",
        productImage: "",
        productMinQty: "",
        productWeight: "",
        productDimensions: "",
        merchantName: "",
        merchantEmail: "",
        merchantWebsite: "",
        merchantPhone: "",
    });
    const [category, setCategory] = useState("select category");
    const {
        productName,
        productPrice,
        productCompPrice,
        productCategory,
        productDescription,
        productImage,
        productMinQty,
        productWeight,
        productDimensions,
        merchantName,
        merchantEmail,
        merchantWebsite,
        merchantPhone,
    } = inputValue;

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            productCategory: category,
            [name]: value,
        });
        console.log(inputValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let productDetails = {
            product_id: crypto.randomUUID().slice(0, 6),
            name: productName,
            price: productPrice
                ? {
                      whole: (parseFloat(productPrice).toFixed(2) + "").split('.')[0],
                      fraction: (parseFloat(productPrice).toFixed(2) + "").split('.')[1],
                  }
                : {
                      whole: 1,
                      fraction: 50,
                  },
            comp_price: productCompPrice
                ? {
                      whole: (parseFloat(productPrice).toFixed(2) + "").split('.')[0],
                      fraction: (parseFloat(productPrice).toFixed(2) + "").split('.')[1],
                  }
                : {
                      whole: 1,
                      fraction: 50,
                  },
            category: category,
            description: productDescription,
            image: productImage,
            min_qty: productMinQty,
            weight: productWeight ? productWeight : "1.0 grams",
            dimensions: productDimensions ? productDimensions : "1.0 x 1.0 x 1.0 cm",
            merchant_info: {
                name: merchantName,
                email: merchantEmail,
                website: merchantWebsite,
                phone_number: merchantPhone,
                address: {},
            },
        };

        const options = {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(productDetails),
        };

        let res = await fetch(`${process.env.REACT_APP_API_URL}/products`, options);
        console.log(res.status);
        let data = await res.json();

        console.log("submitted", data);
        setInputValue({
            productName: "",
            productPrice: "",
            productCompPrice: "",
            productCategory: "",
            productDescription: "",
            productImage: "",
            productMinQty: "",
            productWeight: "",
            productLength: "",
            productWidth: "",
            productHeight: "",
            merchantName: "",
            merchantEmail: "",
            merchantWebsite: "",
            merchantPhone: "",
        });
        setProducts([...products, data]);
    };

    const clearInputValue = () => {
        setInputValue({
            productName: "",
            productPrice: "",
            productCompPrice: "",
            productCategory: "",
            productDescription: "",
            productImage: "",
            productMinQty: "",
            productWeight: "",
            productDimensions: "",
            merchantName: "",
            merchantEmail: "",
            merchantWebsite: "",
            merchantPhone: "",
        });
    };

    const handleImportChange = (e) => {
        setImportData(e.target.value);
    };

    const handleImportSubmit = () => {
        setInputValue({
            ...inputValue,
            productName: getTitle(importData),
            productImage: getImage(importData),
            productMinQty: getMinQty(importData),
            productPrice: getPrice(importData),
            productDimensions: getDimensions(importData),
            productWeight: getWeight(importData),
            productDescription: getDescription(importData),
        });

        setImportData("");
        setFormView("default");
    };

    const alertConfirm = () => {
        alert("Product Successfully submitted!");
    };

    return (
        <div className="NewProductForm w-100 d-flex flex-column align-items-center">
            <div className="col-12 text-start ms-3">
                <div className="col-10 py-2 mb-4 mx-auto">
                    <div className="row justify-content-end px-0">
                        <div className="col-3">
                            <button
                                className="btn btn-primary btn-lg w-100"
                                onClick={() => setFormView(formView === "default" ? "input" : "default")}
                            >
                                {formView === "default" ? "Import from HTML" : "Go back"}
                            </button>
                            <div className="col-12 d-flex justify-content-end align-items-center mt-3">
                                {formView === "default" ? (
                                    <button className="btn btn-danger" onClick={() => clearInputValue()}>
                                        Clear form
                                    </button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                    {formView === "default" ? (
                        //  ----------- First Form View -----------
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="productName" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="productName"
                                    value={productName}
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="mb-3 row">
                                <div className="col-3 text-center">
                                    <label htmlFor="productPrice" className="form-label">
                                        Price
                                    </label>
                                    <div className="input-group">
                                        <span className="input-group-text fs-5">$</span>
                                        <input
                                            type="number"
                                            min="0.01"
                                            step="0.01"
                                            className="form-control fs-5"
                                            name="productPrice"
                                            value={productPrice}
                                            onChange={handleOnChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-4 text-center">
                                    <label htmlFor="productCompPrice" className="form-label">
                                        Compared Price
                                    </label>
                                    <div className="input-group">
                                        <span className="input-group-text fs-5">$</span>
                                        <input
                                            type="number"
                                            min="0.01"
                                            step="0.01"
                                            className="form-control fs-5"
                                            name="productCompPrice"
                                            value={productCompPrice}
                                            onChange={handleOnChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-5 text-center">
                                    <label htmlFor="productCategory" className="form-label">
                                        Category
                                    </label>
                                    {/* dropdown */}

                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-dark btn-lg dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {category}
                                        </button>
                                        <ul className="dropdown-menu">
                                            {categories.map((c) => (
                                                <li key={c.name}>
                                                    <p
                                                        className="dropdown-item dd-item-hover mb-0"
                                                        onClick={() => setCategory(c.name)}
                                                    >
                                                        {c.name}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    {/* <input
                                    type="text"
                                    className="form-control"
                                    name="productCategory"
                                    value={productCategory}
                                    onChange={handleOnChange}
                                /> */}
                                    {/* dropdown */}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="productDescription" className="form-label">
                                    Description
                                </label>
                                <textarea
                                    type="text"
                                    rows="5"
                                    className="form-control"
                                    name="productDescription"
                                    value={productDescription}
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="productImage" className="form-label">
                                    Image URL
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="productImage"
                                    value={productImage}
                                    onChange={handleOnChange}
                                />
                            </div>

                            <div className="mb-3 row">
                                <div className="col-6 text-center">
                                    <label htmlFor="productMinQty" className="form-label">
                                        Min Qty for Order
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="productMinQty"
                                            value={productMinQty}
                                            onChange={handleOnChange}
                                        />
                                        <span className="input-group-text fs-6">units</span>
                                    </div>
                                </div>
                                <div className="col-6 text-center">
                                    <label htmlFor="productWeight" className="form-label">
                                        Weight
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control fs-5"
                                            name="productWeight"
                                            value={productWeight}
                                            onChange={handleOnChange}
                                        />
                                        <span className="input-group-text fs-6">(include units)</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div>
                                    <label htmlFor="productDimensions" className="form-label">
                                        Dimensions
                                    </label>
                                </div>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control fs-5"
                                        name="productDimensions"
                                        value={productDimensions}
                                        onChange={handleOnChange}
                                    />
                                    <span className="input-group-text fs-6">(include units)</span>
                                </div>
                                {/* <div className="row text-center">
                                <div className="col-4 form-label fs-6">Length</div>
                                <div className="col-4 form-label fs-6">Width</div>
                                <div className="col-4 form-label fs-6">Height</div>
                            </div>
                            <div className="row">
                                <div className="input-group input-group-sm col">
                                    <input
                                        type="number"
                                        min="0.01"
                                        step="0.01"
                                        className="form-control fs-5"
                                        name="productLength"
                                        value={productLength}
                                        onChange={handleOnChange}
                                    />
                                    <span className="input-group-text fs-6">in</span>
                                </div>
                                <div className="input-group input-group-sm col">
                                    <input
                                        type="number"
                                        min="0.01"
                                        step="0.01"
                                        className="form-control fs-5"
                                        name="productWidth"
                                        value={productWidth}
                                        onChange={handleOnChange}
                                    />
                                    <span className="input-group-text fs-6">in</span>
                                </div>
                                <div className="input-group input-group-sm col">
                                    <input
                                        type="number"
                                        min="0.01"
                                        step="0.01"
                                        className="form-control fs-5"
                                        name="productHeight"
                                        value={productHeight}
                                        onChange={handleOnChange}
                                    />
                                    <span className="input-group-text fs-6">in</span>
                                </div>
                            </div> */}
                            </div>
                            <h1 className="display-5 mt-5 mb-3">Merchant Info</h1>
                            <div className="mb-3">
                                <label htmlFor="merchantName" className="form-label">
                                    Merchant Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="merchantName"
                                    value={merchantName}
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="merchantEmail" className="form-label">
                                    Merchant Email
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="merchantEmail"
                                    value={merchantEmail}
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="merchantWebsite" className="form-label">
                                    Merchant Website
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="merchantWebsite"
                                    value={merchantWebsite}
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="merchantPhone" className="form-label">
                                    Merchant Phone
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="merchantPhone"
                                    value={merchantPhone}
                                    onChange={handleOnChange}
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg"
                                data-bs-dismiss="modal"
                                data-bs-target="#newProductModal"
                                aria-label="Close"
                                onClick={alertConfirm}
                            >
                                Submit Product
                            </button>
                        </form>
                    ) : (
                        // ----------- Second Form View -----------
                        <form>
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button
                                            class="accordion-button fs-4"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Instructions
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse show"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="accordion-body">
                                            1. Find a product page, right click on the page and select "Inspect" to pull up dev console.
                                            <br />
                                            2. Scroll up in the Elements tab, and look for the &lt;body&gt; tag (usually
                                            below &lt;head&gt;...&lt;/head&gt;)
                                            <br />
                                            3. Right click on &lt;body&gt;, and select "Edit as HTML"
                                            <br />
                                            4. Make sure your cursor is in the text field, then press Ctrl + A to select
                                            all the text
                                            <br />
                                            5. Ctrl + C to copy the selected text, then come back to Marafyde and paste
                                            in the field with Ctrl + V<br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p></p>
                            <label htmlFor="merchantPhone" className="form-label">
                                Paste 'body' tag from HTML below
                            </label>
                            <textarea
                                type="text"
                                className="form-control mb-5"
                                rows="5"
                                name="productDescription"
                                value={importData}
                                onChange={handleImportChange}
                            />
                            <button className="btn btn-primary btn-lg" onClick={() => handleImportSubmit()}>
                                Import into form
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};
export default NewProductForm;
