import { Link } from "react-router-dom";

import { formatPrice, shortenName } from "../util/functions";

const CarouselCard = (props) => {
    const product = props.productData;
    const compPrice = product.comp_price;
    console.log("Product in CarouselCard", product);

    let cents = formatPrice(product.price.fraction);
    let comp_cents = formatPrice(product.comp_price.fraction);

    return (
        <Link to={`/products/${product.product_id}`} className="col-2 px-2 my-1" style={{ "height": "" }}>
            <div className="py-2 mx-3 px-2" style={{height: "12rem", maxWidth: "12rem"}}>
                <div className="img-container d-flex align-items-center justify-content-center" style={{ height: "75%"}}>
                    <img src={product.image} className="img-fluid mh-100" alt="..." style={{}}/>
                </div>
                <div className="carousel-card-body card-body mt-0" style={{height: "3rem"}}>
                    {/* <h5 className="fs-6 h-50">{shortenName(product.name)}</h5> */}
                    <p className="card-text lead fs-5 fw-bold text-secondary mb-0 me-4 pb-0">
                        <span className="fs-6 fw-normal text-secondary">$</span>{compPrice.whole}.<span className="fs-6 align-text-top text-secondary">{comp_cents}</span>
                    </p>
                    <p className="card-text lead fs-3 fw-bold text-dark ms-4 mt-0 pt-0">
                        <span className="fs-5 fw-normal text-dark">$</span>{product.price.whole}.<span className="fs-5 align-text-top text-dark">{cents}</span>
                    </p>

                </div>
            </div>
        </Link>
    );
};

export default CarouselCard;
