import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname;

    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
    });

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (inputValue.password !== inputValue.confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                credentials: "include",
                body: JSON.stringify({password:inputValue.password}),
            });
            const data = await res.json();

            const { success, message } = data;
            if (success) {
                alert(message);
                navigate("/login");
            } else {
                alert(message);
            }
        } catch {
            console.log("Error");
        }
    };

    return (
        <>
            <div className="Login justify-content-center d-flex h-100 align-items-center">
                <div className="row col-xl-6 col-md-8 col-11 py-5 mx-2">
                    <form onSubmit={handleSubmit} className="p-4 p-md-5 border rounded-3 bg-light">
                        <h1 className="display-2 mb-4">Reset Password</h1>
                        <div className="form-floating mb-3">
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                // value={email}
                                onChange={handleOnChange}
                            />
                            <label className="float-bg" htmlFor="floatingInput">
                                New Password
                            </label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="password"
                                name="confirmPassword"
                                className="form-control"
                                // value={password}
                                onChange={handleOnChange}
                            />
                            <label className="float-bg" htmlFor="floatingPassword">
                                Confirm Password
                            </label>
                        </div>
                        <button className="w-100 btn btn-lg btn-primary" type="submit">
                            Update Password
                        </button>
                    </form>
                    {/* <ToastContainer /> */}
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
