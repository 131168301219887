import { useEffect, useState } from "react";

function AdminOrdersPanel() {
    let [orders, setOrders] = useState([]);

    const getOrders = async () => {


        let prodRes = await fetch(`${process.env.REACT_APP_API_URL}/products`);
        let prodList = await prodRes.json();

        let orderRes = await fetch(`${process.env.REACT_APP_API_URL}/orders`);
        let orderList = await orderRes.json();

        console.log("Product List: ", prodList);
        console.log("Order List: ", orderList);

        let arr = [];
        for (let i = 0; i < prodList.length; i++) {
            arr.push({ p: prodList[i], o: orderList[i] });
        }
        setOrders(arr);
    };

    useEffect(() => {
        getOrders();
    }, []);

    if (orders.length === 0 || !orders) {
        return <></>
    }

    return (
        <div className="AdminOrdersPanel d-flex flex-column align-items-center">
            <div className="col-12 text-start">
                <h3 className="display-4 text-center">Orders:</h3>
                {orders.map((item) => (
                    <div key={item.product_id} className="col-12 d-flex flex-row justify-content-center">
                        <div className="col-8 flex-row align-items-center d-flex">
                            <div className="col-3">
                                <img src={item.p.image} alt="" className="img-fluid" />
                            </div>
                            <div className="col-3">{item.p.name}</div>
                            <div className="col-3">Current Qty: {item.o.current_qty}</div>
                            <div className="col-3">Min Qty to Order: {item.p.min_qty}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminOrdersPanel;
