const UserPanelHome = (props) => {

    let user = props.user

    return (
        <div className="UserPanelHome d-flex flex-column align-items-center">
            <h1 className="mt-5">Your Account</h1>
            <h2 className="lead fs-4 mt-5">(Under Construction)</h2>
        </div>
    );
};

export default UserPanelHome;
