let OrderBar = (props) => {
    const order = props.order;

    let qty = order.current_qty || 0;
    let min_qty = order.prod_min_qty || 100;

    let { barStyle, textStyle } = props;
    // // console.log("qty", qty);
    // // console.log("min", min_qty);
    let percent = Math.floor((qty / min_qty) * 100);
    // // console.log("percent", percent);


    const findColor = (percent) => {
        if (percent === 0) return "#ffffff"
        return "#4daaff"
        // if (percent >= 75) return "#00ff26";
        // else if (percent >= 50) return "yellow";
        // else if (percent >= 25) return "orange";
        // else return "red";
    };

    const getPercent = () => {
        return `${percent}%`;
    };


    return (
        <>
            <div className="row text-center d-flex">
                <p className="mb-0" style={textStyle}>Order is {percent}% filled</p>
            </div>
            <div className="OrderBar row text-center px-0 border border-2 rounded" style={barStyle}>
                <div
                    style={{ width: getPercent(), height: "100%", backgroundColor: findColor(percent)}}
                    className="justify-content-center my-auto border-0 rounded"
                ></div>
            </div>
        </>
    );
};

export default OrderBar;
