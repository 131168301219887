import { useState, useEffect } from "react";
import { capsFirstLetter } from "../../util/functions";

import { Link } from "react-router-dom";
let HomeCategories = () => {
    let [categories, setCategories] = useState([]);

    useEffect(() => {
        const getCategories = async () => {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/categories`);
            let categoryData = await res.json();

            setCategories(categoryData);
        };

        getCategories();
    }, []);
    return (
        <section className="HomeCategories page-section mt-1" id="category">
            <div className="container">
                <div className="text-center"><h1 className="home-title mb-4">BROWSE CATEGORIES</h1></div>
                <div className="row">


                    {/* Implement later, need pictures with backgrounds for category images - need to be similar sizes too */}
                    {/* {categories.map((category) => (
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <div className="category-item">
                                <Link to={`/products?search=${category.name}`} className="category-link">
                                    <div className="category-hover">
                                        <div className="category-hover-content">
                                            <i className="fas fa-plus fa-3x"></i>
                                        </div>
                                    </div>
                                    <img className="img-fluid category-img" src={category.images[0]} alt="..." />
                                </Link>
                                <div className="category-caption">
                                    <div className="category-caption-heading">{capsFirstLetter(category.name)}</div>
                                </div>
                            </div>
                        </div>
                    ))} */}


                    {/* <!------------------------- Category Item 1 -------------------------> */}
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="category-item">
                            <Link to="/products?search=electronics" className="category-link">
                                <div className="category-hover">
                                    <div className="category-hover-content">
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img className="img-fluid category-image" src="category_images/laptop_banner1.jpg" alt="..." />
                            </Link>
                            <div className="category-caption">
                                <div className="category-caption-heading">Electronics</div>
                            </div>
                        </div>
                    </div>
                    {/* <!------------------------- Category Item 2 -------------------------> */}
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="category-item">
                            <Link to="/products?search=shoes" className="category-link">
                                <div className="category-hover">
                                    <div className="category-hover-content">
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img className="img-fluid category-image" src="category_images/shoes_banner2.jpg" alt="..." />
                            </Link>
                            <div className="category-caption">
                                <div className="category-caption-heading">Shoes</div>
                            </div>
                        </div>
                    </div>
                    {/* <!------------------------- Category Item 3 -------------------------> */}
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="category-item">
                            <Link to="/products?search=clothing" className="category-link">
                                <div className="category-hover">
                                    <div className="category-hover-content">
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img className="img-fluid category-image" src="category_images/clothing_banner1.jpg" alt="..." />
                            </Link>
                            <div className="category-caption">
                                <div className="category-caption-heading">Clothing</div>
                            </div>
                        </div>
                    </div>
                    {/* <!------------------------- Category Item 4 -------------------------> */}
                    <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
                        <div className="category-item">
                            <Link to="/products?search=food%20&%20drink" className="category-link">
                                <div className="category-hover">
                                    <div className="category-hover-content">
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img className="img-fluid category-image" src="category_images/home-kitchen_banner1.jpg" alt="..." />
                            </Link>
                            <div className="category-caption">
                                <div className="category-caption-heading">Home & Kitchen</div>
                            </div>
                        </div>
                    </div>
                    {/* <!------------------------- Category Item 5 -------------------------> */}
                    <div className="col-lg-4 col-sm-6">
                        <div className="category-item">
                            <Link to="/products?search=toys" className="category-link">
                                <div className="category-hover">
                                    <div className="category-hover-content">
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img className="img-fluid category-image" src="category_images/toys_banner1.jpg" alt="..." />
                            </Link>
                            <div className="category-caption">
                                <div className="category-caption-heading">Toys</div>
                            </div>
                        </div>
                    </div>
                    {/* <!------------------------- Category Item 6 -------------------------> */}
                    <div className="col-lg-4 col-sm-6 mb-4 mb-sm-0">
                        <div className="category-item">
                            <Link to="/products?search=electronics" className="category-link">
                                <div className="category-hover">
                                    <div className="category-hover-content">
                                        <i className="fas fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <img className="img-fluid category-image" src="category_images/accessories_banner3.jpg" alt="..." />
                            </Link>
                            <div className="category-caption">
                                <div className="category-caption-heading">Accessories</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeCategories;
