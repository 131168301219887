import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProductCard from "./ProductCard";

import { filterProducts } from '../util/search'

function ProductList() {
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);

    const location = useLocation();

    let search = decodeURIComponent(location.search.slice(8))
    console.log(search)

    useEffect(() => {
        const getProducts = async () => {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/products`);
            let productData = await res.json();

            let filtered = filterProducts(productData, search);
            setProducts(filtered);
        };

        const getOrders = async () => {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/orders`);
            let ordersData = await res.json();
            setOrders(ordersData);
        };

        getProducts();
        getOrders();
    }, [location, search]);

    if (products[0] === "none"){
        return (
            <div className="col-12">
                <h1 className="lead fs-3 mt-4 mb-4">No Results found for "<span className="lead fs-3" style={{fontWeight: 600}}>{search}</span>"</h1>
            </div>
        )
    }

    if ((!products || products.length === 0) || (!orders || orders.length === 0)) {
        return <></>;
        // return <div>Loading...</div>;
    }

    return (
        <div className="ProductList justify-content-center my-4">
            <div className="mx-auto px-5 text-start">
                {search ? <h1 className="lead fs-3 mt-2 mb-4 px-2 mx-1">Results for: "<span className="lead fs-3" style={{fontWeight: 600}}>{search}</span>"</h1> : <></>}
            </div>
            <div className="mx-auto d-flex flex-wrap justify-content-start px-5">
                {products.map((product) => (
                    <ProductCard key={product.product_id} productData={product} orders={orders}/>
                ))}
            </div>
        </div>
    );
}

export default ProductList;
