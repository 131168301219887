import { useEffect, useState } from "react";
import AdminPanelTabs from './AP_Tabs'

const AdminPanelMain = (props) => {
    let [tab, setTab] = useState("products_new")

    const user = props.user

    useEffect(() => {

    }, [tab]);
    return (
        <div className="AdminPanelMain d-flex flex-column align-items-center">
            <div className="col-12 d-flex flex-row justify-content-end">
                <div className="col-3 d-flex flex-column align-items-end px-1 pt-3 pe-3">
                    <h1 className="display-5">Hello, {user.username}</h1>
                </div>
            </div>
            <div className="col-12 mb-5">
                <div className="container d-flex justify-content-center">
                    <div className="col-lg-8 col-10">
                        <div className="row py-1">
                            <div className="col-sm-3 col-6 d-flex flex-row justify-content-center">
                                <button className="btn btn-lg btn-outline-primary fs-4 w-100 py-3" onClick={() => setTab("products")}>Products</button>
                            </div>
                            <div className="col-sm-3 col-6 d-flex flex-row justify-content-center">
                                <button className="btn btn-lg btn-outline-primary fs-4 w-100 py-3" onClick={() => setTab("categories")}>Categories</button>
                            </div>
                            <div className="col-sm-3 col-6 d-flex flex-row justify-content-center">
                                <button className="btn btn-lg btn-outline-primary fs-4 w-100 py-3" onClick={() => setTab("orders")}>Orders</button>
                            </div>
                            <div className="col-sm-3 col-6 d-flex flex-row justify-content-center">
                                <button className="btn btn-lg btn-outline-primary fs-4 w-100 py-3" onClick={() => setTab("users")}>Users</button>
                            </div>
                        </div>
                        <div className="row py-1">
                        </div>
                    </div>
                </div>
            </div>
            <AdminPanelTabs tab={tab} user={user}/>
        </div>
    );
};

export default AdminPanelMain;
