import { formatPrice } from "../../../../util/functions";
import AdminCardButtons from "./AdminCardButtons"

const AdminProductCard = (props) => {
    const product = props.productData;

    let cents = formatPrice(product.price.fraction);

    return (
        <div
            to={`/products/${product.product_id}`}
            className="AdminProductCard col-6 mx-auto px-4 mb-4 text-dark d-flex flex-column justify-content-center"
            style={{ height: "18rem" }}
        >
            <div className="ProductCardInner row border h-100">
                <div className="d-flex justify-content-center align-items-center border p-3 bg-light" style={{width: "18rem"}}>
                    <img src={product.image} className="img-fluid" alt="product" style={{ height: "15rem" }} />
                </div>
                <div className="col pt-1 ps-4 d-flex flex-column justify-content-center">
                    <div className="d-flex flex-column align-items-start justify-content-center" style={{height: "80%"}}>
                        <h5 className="product-name fw-bold lead fs-4 mb-3 text-start" style={{ fontSize: "1.2rem" }}>
                            {product.name}
                        </h5>
                        <p className="card-price fs-6">
                            <span className="fs-2 display-3">${product.price.whole}.</span>
                            {cents}
                        </p>
                    </div>
                    <AdminCardButtons product={product} setUpdated={props.setUpdated} setCurrentProduct={props.setCurrentProduct}/>
                </div>
            </div>
        </div>
    );
};

export default AdminProductCard;
