import { useContext, useEffect, useState } from "react";
import UserContext from "../context/userContext";
import { Link } from "react-router-dom";
import { formatPrice, calcCartPrice } from "../util/functions";
import Checkout from "./Checkout";

const Cart = () => {
    const [cart, setCart] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageShowing, setPageShowing] = useState("cart");
    const { user } = useContext(UserContext);


    const removeFromCart = async (id) => {
        let newCart = [];
        if (id) {
            newCart = cart.filter((item) => item.product_id !== id);
        }

        console.log(`Remove ${id} from cart`);

        const body = {
            cart: newCart,
        };

        await fetch(`${process.env.REACT_APP_API_URL}/cart/${user}`, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(body),
        });
        setIsLoading(true);
    };

    useEffect(() => {
        const getUserCart = async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/cart/${user}`);
            const userCart = await res.json();

            console.log("cart", cart);

            setCart(userCart);
            setIsLoading(false);
        };

        if (user) {
            getUserCart(user);
        } else {
            setIsLoading(false);
        }
    }, [user, isLoading]);

    if (isLoading) {
        return <></>;
    }

    if (pageShowing === "checkout") {
        return (
            <>
                <Checkout cart={cart} setCart={setCart} user={user} removeFromCart={removeFromCart}/>
            </>
        );
    }

    if (pageShowing === "cart") {
        if (!user) {
            return (
                <div className="Cart d-flex flex-column justify-content-center col-md-6 col-10 mx-auto my-auto">
                    <div className="row">
                        <h1 className="display-4 mb-4">Your Marafyde cart is empty.</h1>
                        <h2 className="lead fs-4">Please log in to start adding items to your cart</h2>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <Link className="btn btn-lg btn-primary col-xl-3 col-md-5 col-5 my-1 px-2 mx-3" to="/login">
                            Log in
                        </Link>
                        <Link
                            className="btn btn-lg btn-outline-primary col-xl-3 col-md-5 col-5 my-1 px-2 mx-3"
                            to="/signup"
                        >
                            Sign up now
                        </Link>
                    </div>
                </div>
            );
        }

        if (cart.length === 0) {
            return (
                <div className="Cart justify-content-center my-5">
                    <div className="col-12 d-flex flex-column justify-content-center">
                        <h1 className="display-5">Your Marafyde cart is empty.</h1>
                    </div>
                </div>
            );
        }

        return (
            <div className="Cart justify-content-center my-5">
                <div className="col-12 d-flex flex-column justify-content-center">
                    <h1 className="display-3 mt-2">Your Cart</h1>
                    {cart.map((item) => (
                        <div key={item.product_id} className="col-12 d-flex flex-row justify-content-center">
                            <div style={{ height: "10rem" }} className="col-10 flex-row d-flex align-items-center">
                                <div className="col-3">
                                    <img style={{ height: "10rem" }} src={item.image} alt="" className="img-fluid" />
                                </div>
                                <div className="col-3">{item.product_name}</div>
                                <div className="col-2">Price: ${(item.product_price * item.qty).toFixed(2)}</div>
                                <div className="col-1">Qty: {item.qty}</div>
                                <div className="col">
                                    <button onClick={() => removeFromCart(item.product_id)} className="btn btn-danger">
                                        Remove item from cart
                                    </button>
                                    {/* Button to add item to list / save for later */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <button onClick={() => setPageShowing("checkout")} type="submit" className="btn btn-primary mt-5">
                    Continue to checkout
                </button>
            </div>
        );
    }
};

export default Cart;
