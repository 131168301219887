import { useEffect, useState } from "react";
import NewListModal from "./Lists/NewListModal";
import ListView from "./Lists/ListView";

const UserPanelLists = (props) => {
    let [view, setView] = useState("showLists");

    let lists = props.user.lists;
    let email = props.user.email;
    let username = props.user.username;

    if (view === "showLists") {
        return (
            <div className="UserPanelLists d-flex flex-column align-items-center mt-5 mb-5">
                <div className="row w-100">
                    <h1 className="display-5">Your Lists:</h1>
                </div>
                <div className="col-10 mx-auto d-flex flex-row justify-content-end">
                    <button
                        className="btn btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#newListModal"
                        type="button"
                    >
                        Create new List
                    </button>
                </div>
                {/* <div class="accordion col-10" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                Accordion Item #1
                            </button>
                        </h2>
                        <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body"></div>
                        </div>
                    </div>
                </div> */}

                {lists.map((list) => (
                    <ListView list={list} n={lists.indexOf(list)}/>
                ))}
                <NewListModal username={username} />
            </div>
        );
    }
};

export default UserPanelLists;
