import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import userContext from "../context/userContext";
// import cartContext from "../context/cartContext";


// Commented out lines are for updating cart on sign in, might change later


const SignUp = () => {
    const { setUser } = useContext(userContext);
    // const { cart, setCart } = useContext(cartContext);

    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState({
        email: "",
        username: "",
        password: "",
        passwordConfirm: "",
    });

    const { email, username, password, passwordConfirm } = inputValue;

    // const getUserCart = async (email) => {
    //     const res = await fetch(`${process.env.REACT_APP_API_URL}/users/cart`, {
    //         method: "GET",
    //         mode: "cors",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         credentials: "include",
    //         body: JSON.stringify({email: email}),
    //     });
    //     const cart = await res.json();

    //     console.log("User's cart in SignUp.js is ", cart)

    //     return cart
    // }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            [name]: value,
        });
    };

    const handleError = (err) => {
        toast.error(err, {
            position: "bottom-left",
        });
    };
    const handleSuccess = (msg) => {
        toast.success(msg, {
            position: "bottom-right",
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(inputValue);
        try {
            const body = {
                ...inputValue,
                address: {},
            }
            const res = await fetch(`${process.env.REACT_APP_API_URL}/signup`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(inputValue),
            });
            const data = await res.json();
            console.log(`\nData: ${JSON.stringify(data)}\n\n\n`);
            const { success, message } = data;
            if (success) {
                handleSuccess(message);

                setUser(inputValue.email)
                // setCart(await getUserCart(inputValue.email))

                setTimeout(() => {
                    navigate("/account");
                }, 1000);
            } else {
                handleError(message);
            }
        } catch (error) {
            console.log(error);
        }
        setInputValue({
            ...inputValue,
            email: "",
            username: "",
            password: "",
            passwordConfirm: "",
        });
    };

    return (
        <div className="SignUp justify-content-center d-flex h-100 align-items-center">
            <div className="row col-xl-6 col-md-8 col-11 pt-5 pb-4 mx-2">
                <form className="p-4 p-md-5 border rounded-3 bg-light" onSubmit={handleSubmit}>
                    <h1 className="display-2 mb-4">Sign Up</h1>
                    <div className="form-floating mb-3">
                        <input
                            type="email"
                            name="email"
                            value={email}
                            className="form-control"
                            onChange={handleOnChange}
                        />
                        <label className="float-bg" htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            name="username"
                            value={username}
                            className="form-control"
                            onChange={handleOnChange}
                        />
                        <label className="float-bg" htmlFor="floatingInput">Username</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="password"
                            name="password"
                            value={password}
                            className="form-control"
                            id="floatingPassword"
                            onChange={handleOnChange}
                        />
                        <label className="float-bg" htmlFor="floatingPassword">Password</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="password"
                            name="passwordConfirm"
                            value={passwordConfirm}
                            className="form-control"
                            id="floatingPasswordConfirm"
                            onChange={handleOnChange}
                        />
                        <label className="float-bg" htmlFor="floatingPasswordConfirm">Confirm Password</label>
                    </div>
                    <button className="w-100 btn btn-lg btn-primary mb-3" type="submit">
                        Sign up
                    </button>
                    <small className="text-muted">
                        Already have an account? <Link to="/login">Login</Link>
                    </small>
                    <hr className="mt-4" />
                    <small className="text-muted my-4">By clicking Sign up, you agree to the terms of use.</small>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
};

export default SignUp;
