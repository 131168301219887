import { useEffect, useState } from "react";

function UsersPanel() {
    let [users, setUsers] = useState([]);

    const getUsers = async () => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/users`);
        let data = await res.json();

        setUsers(data);
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div className="UsersPanel col-12 d-flex flex-column align-items-center">
            <div className="col-12 text-start ms-3">
                <h3 className="display-4 col-12 text-center">Users:</h3>
                <div className="col-12 d-flex flex-row justify-content-center mx-3">
                        <div className="col-8 flex-row align-items-center d-flex">
                            <div className="col-4 fw-bold">Username</div>
                            <div className="col-5 fw-bold">Email</div>
                            <div className="col-3 fw-bold">Account Type</div>
                        </div>
                    </div>
                {users.map((user) => (
                    <div key={user.email} className="col-12 d-flex flex-row justify-content-center mx-3 border">
                        <div className="col-8 flex-row align-items-center d-flex">
                            <div className="col-4">{user.username}</div>
                            <div className="col-5">{user.email}</div>
                            <div className="col-3">{user.account_type}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UsersPanel;
