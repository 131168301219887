import "./Home.css";
import { useState, useEffect } from "react";

import HomeCarousel from "./HomeCarousel";
import HomeCategories from "./HomeCategories";
import HomeHowItWorks from "./HomeHowItWorks";
import HomeContactUs from "./HomeContactUs";
import ProductList from "../ProductList";

const Home = () => {
    let [products, setProducts] = useState([]);

    useEffect(() => {
        const getProducts = async () => {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/products`);
            let productData = await res.json();

            setProducts(productData);
        };
        getProducts();
    }, []);

    if (products.length === 0 || !products) {
        return (
            <>
                {/* <!-- Masthead--> */}
                <header className="masthead pt-1">
                    <HomeCarousel dir="right" slc={[0, 10]} products={products} />
                    <div className="container-fluid text-dark">
                        <div className="masthead-heading text-uppercase mb-3">Welcome To Marafyde!</div>
                        {/* <div className="masthead-subheading mt-1">Retail items at wholesale cost</div> */}
                        <HomeHowItWorks />
                        {/* <a className="btn btn-primary btn-xl text-uppercase" href="#services">
                        Learn More
                    </a> */}
                    </div>
                    <HomeCarousel dir="left" slc={[10, 20]} products={products} />
                </header>

                <HomeCategories />
                <HomeContactUs />
            </>
        );
    }

    return (
        <>
            {/* <!-- Masthead--> */}
            <header className="masthead pt-1">
                <HomeCarousel dir="right" slc={[0, 10]} products={products} />
                <div className="container-fluid text-dark">
                    <div className="masthead-heading text-uppercase mb-3">Welcome To Marafyde!</div>
                    {/* <div className="masthead-subheading mt-1">Retail items at wholesale cost</div> */}
                    <HomeHowItWorks />
                    {/* <a className="btn btn-primary btn-xl text-uppercase" href="#services">
                        Learn More
                    </a> */}
                </div>
                <HomeCarousel dir="left" slc={[10, 20]} products={products} />
            </header>

            <HomeCategories />
            <div className="text-center"><h1 className="home-title mb-0">PRODUCTS</h1></div>
            <ProductList />
            <HomeContactUs />
        </>
    );
};

export default Home;
