import NavLeft from "./NavLeft";
import NavSearchBar from "./NavSearchBar";
import NavRight from "./NavRight";

const NavBar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="d-flex justify-content-between container-fluid" id="navbarSupportedContent">
                <div className="col-xl d-flex justify-content-start align-items-center">
                    <NavLeft />
                </div>
                <div className="col col-lg-3 d-flex justify-content-center px-3" style={{ minWidth: "50%" }}>
                    <NavSearchBar />
                </div>
                <div className="col-xl col-lg-4 d-flex justify-self-end justify-content-end mb-0" id="navbarMenuLinks">
                    <NavRight />
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
