import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className="Footer py-1 bg-dark justify-self-end mt-auto">
            <ul className="nav justify-content-center">
                <li className="nav-item">
                    <Link to="/" className="footer-link nav-link px-2 text-light">
                    &nbsp;Home&nbsp;
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/products" className="footer-link nav-link px-2 text-light">
                    &nbsp;Products&nbsp;
                    </Link>
                </li>
                {/* <li className="nav-item">
                    <Link to="/pricing" className="footer-link nav-link px-2 text-light">
                    &nbsp;Pricing&nbsp;
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/faq" className="footer-link nav-link px-2 text-light">
                    &nbsp;FAQs&nbsp;
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/about" className="footer-link nav-link px-2 text-light">
                    &nbsp;About&nbsp;
                    </Link>
                </li> */}
            </ul>
            <h6 className="text-center text-light mb-2 footer-copyright">© 2023 Marafyde Inc</h6>
        </div>
    );
}

export default Footer;
