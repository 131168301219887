import AccDropdown from "./AccDropdown";
import CartDropdown from "./CartDropdown";
import ProductsDropdown from "./ProductsDropdown";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import { useState } from "react";
import "./Nav.css";

const NavRight = () => {
    const [menuShowing, setMenuShowing] = useState(false);

    return (
        <>
            <ul className="navbar-nav mb-0 align-items-end navFull">
                <ProductsDropdown />
                <AccDropdown />
                <CartDropdown />
            </ul>
            <div className="navSmall navbar-nav align-items-center">
                <img
                    onClick={() => setMenuShowing(menuShowing ? false : true)}
                    className="hamburger-icon"
                    src="https://i.imgur.com/BSeLZBW.png"
                    alt=""
                />
            </div>
            <HamburgerMenu menuShowing={menuShowing} setMenuShowing={setMenuShowing} />
        </>
    );
};

export default NavRight;
