import Marquee from "react-fast-marquee";

import CarouselCard from "../CarouselCard";

let HomeCarousel = (props) => {
    console.log("Props object", props)
    let [start, end] = props.slc // slc === slice for how many products to show
    let products = props.products

    return (
        <div className="HomeCarousel mx-auto px-5 mb-1 my-0">
            <Marquee gradient="true" gradientWidth={50} direction={props.dir}>
                {products.slice(start, end).map((p) => (
                    <CarouselCard key={p.product_id} productData={p} />
                ))}
            </Marquee>
        </div>
    );
};

export default HomeCarousel;
