import delivery1 from "./icons/png/delivery1.png"
import delivery2 from "./icons/png/delivery2.png"
import delivery3 from "./icons/png/delivery3.png"
import combine1 from "./icons/png/combine1.png"
import combine2 from "./icons/png/combine2.png"
import combine3 from "./icons/png/combine3.png"
import combine4 from "./icons/png/combine4.png"
import checkout1 from "./icons/png/checkout1.png"
import checkout2 from "./icons/png/checkout2.png"
import checkout3 from "./icons/png/checkout3.png"

let HomeHowItWorks = () => {
    return (
        <section className="HomeHowItWorks" id="services">
            <div className="container">
                <div className="text-center">
                    {/* <h1 className="home-title mb-5">HOW IT WORKS</h1> */}
                </div>
                <div className="row text-center">
                    <div className="col-md-4 d-flex flex-column align-items-center">
                        <img src={checkout2} />
                        <h4 className="my-3">Place an Order</h4>
                        <p className="text-muted">
                            You sign up on our site to participate in a bulk order for no charge.
                        </p>
                    </div>
                    <div className="col-md-4 d-flex flex-column align-items-center">
                        <img src={combine4} />
                        <h4 className="my-3">Wait..</h4>
                        <p className="text-muted">
                            Our system attempts to match you up with other orders of the same item and combine them.
                        </p>
                    </div>
                    <div className="col-md-4 d-flex flex-column align-items-center">
                        <img src={delivery1} />
                        <h4 className="my-3">Shipped!</h4>
                        <p className="text-muted">
                            If the order is fulfilled, we charge your preferred payment method, and your product is shipped to you!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeHowItWorks;
