import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { capsFirstLetter } from "../../util/functions";

const ProductsDropdown = () => {
    const [categories, setCategories] = useState([]);

    const getCategories = async () => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/categories`);
        let cData = await res.json();

        setCategories(cData);
    };

    useEffect(() => {
        getCategories();
    }, []);
    return (
        <li className="nav-item me-1">
            <div className="dropdown-center">
                <div className="nav-link dropdown-toggle" id="category-btn" data-bs-toggle="" aria-expanded="false">
                    Products
                </div>
                <ul className="dropdown-menu text-center">
                    {/* <li><h6 class="dropdown-header">Categories</h6></li> */}
                    <li className="">
                        <Link className="dropdown-item" to={"/products"}>
                            All Products
                        </Link>
                    </li>

                    {categories.map((c) => (
                        <li className="" key={c.name}>
                            <Link className="dropdown-item" to={"/products?search=" + c.name}>
                                {capsFirstLetter(c.name)}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    );
};

export default ProductsDropdown;
