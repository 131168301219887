import { Link, useNavigate } from "react-router-dom";
import { useState } from "react"

const NavSearchBar = () => {
    const [search, setSearch] = useState("");
    const navigate = useNavigate()

    const searchUrl = "/products?search="

    const handleSubmit = (e) => {
        e.preventDefault()

        navigate(searchUrl + encodeURI(search))
        setSearch("")
    }

    const handleOnChange = (e) => {
        const val = e.target.value;

        setSearch(val)
    }


    return (
        <>
            <form className="container-fluid" onSubmit={handleSubmit}>
                <div className="form-group d-flex flex-row rounded-start">
                    <input
                        className="form-control"
                        id="searchbarinput"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        name="search"
                        value={search}
                        onChange={handleOnChange}
                    />
                    <button onClick={handleSubmit} className="btn" id="searchbarbutton" type="submit">
                        <img src="images/searchbaricon.png" alt="" id="searchbaricon" />
                    </button>
                </div>
            </form>
        </>
    );
};

export default NavSearchBar;
