let NewOrderBar = (props) => {
    let order = props.order;
    console.log(order)
    let qty = order.current_qty || 0;
    // let qty = 0;
    let min_qty = order.prod_min_qty || 100;
    // console.log("qty", qty);
    // console.log("min", min_qty);
    let percent = Math.floor((qty / min_qty) * 100);
    // console.log("percent", percent);

    const findColor = () => {
        // return "#4daaff";
        if (percent) return "#4daaff" // light blue
        return("#ffffff")
        // if (percent >= 75) return "#00ff26";
        // else if (percent >= 50) return "yellow";
        // else if (percent >= 25) return "orange";
        // else return "red";
    };

    const getPercent = () => {
        return `${percent}%`;
    };

    return (
        <div className="NewOrderBar row text-center px-0 border border-2 rounded ms-0">
            <p className="order-bar-text mb-0">
                {qty} / {min_qty}
            </p>
            <div
                style={{ width: getPercent(), backgroundColor: findColor() }}
                className="justify-content-center text-center h-100 my-auto border-0 rounded"
            > </div>
        </div>
    );
};

export default NewOrderBar;
