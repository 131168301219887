import { useEffect, useState } from "react";
import { formatPrice } from "../../../../util/functions";

const ListView = (props) => {
    let list = props.list;
    let n = props.n

    return (
        <div key={list._id} className="ListView col-10  justify-content-start border p-3 mt-4">
            <div class="accordion-item">
                <div class="accordion-header d-flex flex-row">
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse" + n}
                        aria-expanded="true"
                        aria-controls={"collapse" + n}
                    >
                        <div className="col-3 text-start">
                            <h2 className="mb-0">{list.name}</h2>
                        </div>
                        <div className="col-3 text-center">
                            <h4 className="mb-0">Items: {list.items.length}</h4>
                        </div>
                    </button>
                </div>
                <div id={"collapse" + n} class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        {list.items.map((item) => (
                            <div className="row align-items-center my-3 mx-2 border">
                                <div className="col-2 d-flex justify-content-start">
                                    <img src={item.image} alt="" className="img-fluid" />
                                </div>
                                <div className="col-4 d-flex justify-content-center fw-bold">{item.name}</div>
                                <div className="col-2">Price: ${item.price.whole}.{formatPrice(item.price.fraction)}</div>
                                <div className="col-2">Category: {item.category}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* <div className="col-3 text-start">
                <h2 className="mb-0">{list.name}</h2>
            </div>
            <div className="col-3 text-center">
                <h4 className="mb-0">Items: {list.items.length}</h4>
            </div>
            <div className="col-6 d-flex flex-row justify-content-end">
                <button className="btn btn-danger mx-2">Delete List</button>
            </div> */}
        </div>
    );
};

export default ListView;
