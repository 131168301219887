import { useState } from 'react'

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const NewListModal = (props) => {
    let [newList, setNewList] = useState('')

    const [anchorEl, setAnchorEl] = useState(null);

    let username = props.username

    const addNewList = async () => {

        let res = await fetch(`${process.env.REACT_APP_API_URL}/users/${username}/lists`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "user_email": props.email,
                "list": {
                    "name": newList,
                    "items": [],
                }
            })
        });


        let data = await res.json()
        console.log(data)
    }

    const handleOnChange = (e) => {
        e.preventDefault()
        setNewList(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setAnchorEl(e.currentTarget);
        if (newList === "" || newList.trim() === ""){

        }
        else {
            addNewList(newList)
        }
        setNewList("")
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <div className="modal fade" id="newListModal" tabIndex="-1" aria-labelledby="newListModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-4" id="newListModalLabel">
                                Create New List
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} className="">
                                <div className="mb-3 col-10 mx-auto">
                                    <label for="newListInput" className="form-label fs-4">
                                        Name of New List:
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        id="newListInput"
                                        aria-describedby="newListHelp"
                                        onChange={handleOnChange}
                                        value={newList}
                                    />
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-3 mt-1">
                                        <button type="submit" className="btn btn-primary btn-lg">
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography onMouseLeave={handleClose} sx={{ p: 2 }}>Successfully added new list!</Typography>
            </Popover>
        </>
    );
};

export default NewListModal;
