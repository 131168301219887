import { useState } from "react";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const NewCategoryModal = (props) => {
    let [newCategory, setNewCategory] = useState("");
    let [message, setMessage] = useState("Category successfully created!");

    let { categories, setCategories } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const addNewCategory = async (tar) => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/categories`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: newCategory,
                images: [],
            }),
        });
        if (res.status !== 200) {
            setMessage("Failed to create category, category already exists");
            setAnchorEl(tar);

        } else {
            setMessage("Category successfully created!");
            setCategories([...categories, { name: newCategory, images: [] }]);
            setAnchorEl(tar);
        }
    };

    const handleOnChange = (e) => {
        e.preventDefault();
        setNewCategory(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newCategory !== "" || newCategory.trim() !== "") {
            addNewCategory(e.currentTarget);
        }
        setNewCategory("");
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <div
                className="modal fade"
                id="newCategoryModal"
                tabIndex="-1"
                aria-labelledby="newCategoryModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-4" id="newCategoryModalLabel">
                                Create New Category
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} className="">
                                <div className="mb-3 col-10 mx-auto">
                                    <label htmlFor="newCategoryInput" className="form-label fs-4">
                                        Name of New Category:
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        id="newCategoryInput"
                                        aria-describedby="newCategoryHelp"
                                        onChange={handleOnChange}
                                        value={newCategory}
                                    />
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-3 mt-1">
                                        <button type="submit" className="btn btn-primary btn-lg">
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography onMouseLeave={handleClose} sx={{ p: 2 }}>
                    {message}
                </Typography>
            </Popover>
        </>
    );
};

export default NewCategoryModal;
